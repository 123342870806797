.ub-reports-banner {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  padding: 4.8rem 0;
  /* larger than mobile */
}
.ub-reports-banner .cta-section {
  display: flex;
  flex-direction: column;
}
.ub-reports-banner .subtitle {
  margin-top: 1.6rem;
}
.ub-reports-banner .cta {
  margin-top: 3.2rem;
}
@media (min-width: 43.8125rem) {
  .ub-reports-banner {
    flex-direction: row-reverse;
    gap: 1.6rem;
    align-items: center;
    justify-content: space-between;
    padding: 0;
  }
  .ub-reports-banner .cta-section {
    align-items: flex-start;
    min-width: 24rem;
    max-width: 31.1rem;
    padding: 2.4rem 0;
  }
  .ub-reports-banner .subtitle {
    margin-top: 2.4rem;
    max-width: 28rem;
  }
  .ub-reports-banner .cta {
    margin-top: 2.4rem;
  }
}
@media (min-width: 61.3125rem) {
  .ub-reports-banner .subtitle {
    max-width: unset;
  }
}
