@container ud-content-area (min-width: @content-area-sm-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) * (3 / 4) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-md-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (6 / 12) + (6 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (4 / 12) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (4 / 9) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-mid) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (3 / 9) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-xl-min) {
  grid-auto-columns: calc((100% - (5 - 1) * 1.6rem) / 5);
}@media (min-width: 37.5625rem) {
  .professional-skills-unit :global(.ud-tab-content) {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
}
.panel-content {
  width: 100%;
  max-width: 134rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 2.4rem;
  padding-left: 2.4rem;
}
@media (max-width: 37.5rem) {
  .panel-content {
    padding-left: 0;
    padding-right: 0;
  }
}
