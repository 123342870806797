@container ud-content-area (min-width: @content-area-sm-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) * (3 / 4) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-md-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (6 / 12) + (6 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (4 / 12) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (4 / 9) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-mid) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (3 / 9) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-xl-min) {
  grid-auto-columns: calc((100% - (5 - 1) * 1.6rem) / 5);
}.fill-background {
  background-color: #f6f7f9;
}
.ud-container-with-common-padding {
  width: 100%;
  max-width: 134rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 2.4rem;
  padding-left: 2.4rem;
}
@media (min-width: 61.3125rem) {
  .ud-container-with-common-padding {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
}
@media (max-width: 61.25rem) {
  .ud-container-with-common-padding {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }
}
@media (max-width: 61.25rem) {
  .marketing-hero-banner {
    padding: 0;
  }
}
.marketing-hero-banner-wide {
  padding: 0;
}
@media (max-width: 43.75rem) {
  .marketing-hero-banner-wide {
    padding: 0 0 1.6rem;
  }
}
/**
 * We have a special case for the Professionals Skills module.
 * It has a different padding on mobile than the other module because in desktop, it has a grey background for
 * subcategory panel inside of it but in the mobile, it doesn't have that grey background. So in order to continue that
 * grey background, we need to give padding to the subcategory panel itself. And for the mobile, we need to give padding
 * to the whole module.
 */
@media (min-width: 37.5625rem) {
}
@media (min-width: 37.5625rem) and (min-width: 61.3125rem) {
  .professionals-skills-module .psu-subcontent-panel {
    padding-bottom: 4.8rem;
  }
}
@media (min-width: 37.5625rem) and (max-width: 61.25rem) {
  .professionals-skills-module .psu-subcontent-panel {
    padding-bottom: 3.2rem;
  }
}
@media (min-width: 37.5625rem) {
}
@media (min-width: 37.5625rem) and (min-width: 61.3125rem) {
  .professionals-skills-module {
    padding-top: 4.8rem;
  }
}
@media (min-width: 37.5625rem) and (max-width: 61.25rem) {
  .professionals-skills-module {
    padding-top: 3.2rem;
  }
}
@media (max-width: 37.5rem) {
}
@media (max-width: 37.5rem) and (min-width: 61.3125rem) {
  .professionals-skills-module {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
}
@media (max-width: 37.5rem) and (max-width: 61.25rem) {
  .professionals-skills-module {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }
}
@media (max-width: 61.25rem) {
}
@media (max-width: 61.25rem) and (min-width: 61.3125rem) {
  .ub-case-studies {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
  }
}
@media (max-width: 61.25rem) and (max-width: 61.25rem) {
  .ub-case-studies {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
  }
}
.partners-banner-wrapper {
  padding: 3.2rem 2.4rem;
}
@media (min-width: 61.3125rem) {
  .partners-banner-wrapper {
    padding: 6.4rem;
  }
}
@media (min-width: 61.3125rem) {
  .guidance-entry-point {
    padding-top: 4.8rem;
  }
}
@media (max-width: 61.25rem) {
  .guidance-entry-point {
    padding-top: 3.2rem;
  }
}
@media (min-width: 61.3125rem) {
  .top-categories {
    padding-top: 4.8rem;
  }
}
@media (max-width: 61.25rem) {
  .top-categories {
    padding-top: 3.2rem;
  }
}
@media (min-width: 61.3125rem) {
  .academies-list {
    padding-top: 4.8rem;
  }
}
@media (max-width: 61.25rem) {
  .academies-list {
    padding-top: 3.2rem;
  }
}
.academies-list .academies-list-wrapper {
  padding: 0;
}
