@container ud-content-area (min-width: @content-area-sm-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) * (3 / 4) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-md-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (6 / 12) + (6 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (4 / 12) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (4 / 9) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-mid) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (3 / 9) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-xl-min) {
  grid-auto-columns: calc((100% - (5 - 1) * 1.6rem) / 5);
}.wrapper {
  display: flex;
  flex-direction: column;
}
.content-container {
  width: 100%;
}
.content-container > * + * {
  margin-top: 2.4rem;
}
.image-container {
  width: 100%;
  height: 100%;
}
.image {
  aspect-ratio: 2;
  background: #d1d2e0;
  width: 100%;
  border-radius: 0.8rem;
  height: auto;
}
.logo-sm {
  display: block;
  padding-bottom: 2.4rem;
}
.logo {
  background-color: #d1d2e0;
  height: 3.2rem;
  width: 24rem;
}
.description {
  background-color: #d1d2e0;
  height: 2.4rem;
  margin-top: 2.4rem;
  width: 100%;
}
.description + .description {
  margin-top: 0.8rem;
}
.description:not(:has(~ .description)) {
  width: 40%;
}
.logo + .description {
  margin-top: 3.2rem;
}
.stats-container {
  display: flex;
  flex-direction: row;
  margin-top: 3.2rem;
}
.stats {
  display: block;
  flex-basis: 50%;
  margin-right: 1.6rem;
}
.stats + .stats {
  margin-right: 0;
}
.stat-title {
  background: #d1d2e0;
  height: 2.8rem;
  width: 6rem;
}
.stat-description {
  background: #d1d2e0;
  height: 1.2rem;
  margin-top: 0.8rem;
  width: 100%;
}
.stat-description:not(:has(~ .stat-description)) {
  margin-top: 0.4rem;
  width: 80%;
}
.cta-button {
  background: #d1d2e0;
  height: 4.8rem;
  width: 100%;
}
.controls {
  background: #d1d2e0;
  height: 1.6rem;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}
@media (min-width: 37.5625rem) {
  .wrapper {
    flex-direction: column;
  }
  .logo {
    height: 3.2rem;
    width: 28rem;
  }
  .image {
    border-radius: 0.8rem;
  }
  .description {
    height: 2.4rem;
    width: 100%;
  }
  .stats {
    margin-right: 1.6rem;
  }
  .stats + .stats {
    margin-right: 0;
  }
  .stat-title {
    height: 3.2rem;
    width: 8.8rem;
  }
  .stat-description {
    height: 1.6rem;
    width: 100%;
  }
  .stat-description + .stat-description {
    width: 20rem;
  }
  .cta-button {
    height: 4.8rem;
    margin-top: 2.4rem;
    width: 100%;
  }
  .controls {
    height: 1.6rem;
    margin-top: 2.4rem;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 61.3125rem) {
  .wrapper {
    flex-direction: row;
  }
  .content-container {
    padding-top: 4.8rem;
    padding-right: 1.6rem;
  }
  .logo {
    height: 4.8rem;
    width: 28rem;
  }
  .description {
    height: 3.2rem;
    width: 100%;
  }
  .description:not(:has(~ .description)) {
    width: unset;
  }
  .image-container {
    flex: 0 0 auto;
    width: 50%;
  }
  .image {
    aspect-ratio: 1;
    border-radius: 0;
    height: auto;
    max-height: 63.6rem;
  }
  .stat-title {
    height: 4.8rem;
    width: 8.8rem;
  }
  .stat-description:not(:has(~ .stat-description)) {
    width: 70%;
  }
  .cta-button {
    height: 4.8rem;
    margin-top: 3.2rem;
    width: 16.4rem;
  }
  .controls {
    height: 5rem;
    margin: 4.8rem auto 4.8rem 0;
    width: 20rem;
  }
}
