@container ud-content-area (min-width: @content-area-sm-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) * (3 / 4) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-md-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (6 / 12) + (6 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (4 / 12) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (4 / 9) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-mid) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (3 / 9) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-xl-min) {
  grid-auto-columns: calc((100% - (5 - 1) * 1.6rem) / 5);
}.trending-skills {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.trending-skills > *:not(:first-child) {
  margin-top: 3.2rem;
}
.trending-skills .content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0 2.4rem;
}
.trending-skills .content .top-skill-with-cta {
  display: flex;
  flex-direction: column;
  gap: 3.4rem 0;
}
.trending-skills .content .wrapped-trending-topics {
  display: flex;
  justify-content: space-between;
  gap: 0 2.4rem;
  margin-top: 3.2rem;
}
@media (max-width: 43.75rem) {
  .trending-skills .content {
    flex-direction: column;
  }
  .trending-skills .content .wrapped-trending-topics {
    gap: 1.6rem 2.4rem;
  }
}
@media (max-width: 37.5rem) {
  .trending-skills .content .wrapped-trending-topics {
    flex-flow: wrap;
  }
  .trending-skills .content .wrapped-trending-topics > * {
    max-width: 45%;
  }
}
.header-with-divider {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #d1d2e0;
  padding-bottom: 1.6rem;
}
