@container ud-content-area (min-width: @content-area-sm-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) * (3 / 4) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-md-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (6 / 12) + (6 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (12 - 1) * 1.6rem) * (4 / 12) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-min) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (4 / 9) + (4 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-xl-mid) {
  /* stylelint-disable-next-line max-line-length */
  grid-auto-columns: calc((100% - (9 - 1) * 1.6rem) * (3 / 9) + (3 - 1) * 1.6rem);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-sm-min) {
  grid-auto-columns: calc((100% - (2 - 1) * 1.6rem) / 2);
}@container ud-content-area (min-width: @content-area-md-min) {
  grid-auto-columns: calc((100% - (3 - 1) * 1.6rem) / 3);
}@container ud-content-area (min-width: @content-area-lg-min) {
  grid-auto-columns: calc((100% - (4 - 1) * 1.6rem) / 4);
}@container ud-content-area (min-width: @content-area-xl-min) {
  grid-auto-columns: calc((100% - (5 - 1) * 1.6rem) / 5);
}.heading-skeleton {
  margin-bottom: 1.6rem;
  height: 4.8rem;
  background-color: #d1d2e0;
}
@media (min-width: 61.3125rem) {
  .heading-skeleton {
    width: 60%;
  }
}
@media (min-width: 43.8125rem) and (max-width: 61.25rem) {
  .heading-skeleton {
    height: 4rem;
  }
}
.description-skeleton {
  margin-bottom: 0.8rem;
  width: 100%;
  height: 2.8rem;
  background-color: #d1d2e0;
}
.description-skeleton-secondary {
  width: 30%;
  height: 2.8rem;
  background-color: #d1d2e0;
}
@media (min-width: 75rem) and (max-width: 61.25rem) {
  .description-skeleton-secondary {
    width: 60%;
  }
}
@media (max-width: 23.4375rem) {
  .description-skeleton-secondary {
    width: 90%;
  }
}
.slide-indicator-skeleton {
  margin-top: 2.4rem;
  width: 50%;
  height: 4.8rem;
  background-color: #d1d2e0;
}
@media (min-width: 75rem) and (max-width: 61.25rem) {
  .description-skeleton {
    height: 2.4rem;
  }
  .description-skeleton-secondary {
    height: 2.4rem;
  }
}
.content-skeleton {
  width: 100%;
}
.title-skeleton {
  display: flex;
  width: 66%;
  height: 2.4rem;
  background-color: #d1d2e0;
  margin-bottom: 0.8rem;
}
.icon-skeleton {
  display: flex;
  flex-direction: row;
  width: 6.4rem;
  height: 6.4rem;
  background-color: #d1d2e0;
}
.image-skeleton {
  background-color: #d1d2e0;
  width: 64rem;
  height: 70rem;
}
@media (min-width: 75rem) and (max-width: 61.25rem) {
  .image-skeleton {
    width: 65.2rem;
    height: 45rem;
  }
}
@media (max-width: 75.1rem) {
  .image-skeleton {
    margin-bottom: 2.4rem;
  }
}
@media (max-width: 23.4375rem) {
  .image-skeleton {
    width: 32.7rem;
    height: 22.5rem;
    margin-bottom: 2.4rem;
  }
}
