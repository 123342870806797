.content {
  margin-top: 3.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2.4rem;
}
@media (max-width: 46.88em) {
  .content {
    flex-direction: column;
    gap: 0;
  }
}
.content .values-column {
  width: 53rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 1fr);
  grid-row-gap: 1.6rem;
  container-type: inline-size;
}
@media (max-width: 61.25rem) {
  .content .values-column {
    width: 45.8rem;
  }
}
.content .panels {
  width: 61rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 61.25rem) {
  .content .panels {
    width: 46rem;
  }
}
.slide picture + div {
  margin-top: 1.6rem;
}
